const state = {
    id: "",
    title: "",
    name: "",
    plantId: "",
    // plantName: "",
};

const mutations = {
    changeID(state, value) {
        state.id = value;
    },
    changeTitle(state, value) {
        state.title = value;
    },
    changeName(state, value) {
        state.name = value;
    },
    changeStationID(state, value) {
        state.plantId = value;
    },
    // changeStationName(state, value) {
    //     state.plantName = value;
    // },
};

export default {
    namespaced: true,
    state,
    mutations,
};
