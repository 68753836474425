const leftTreeData = sessionStorage.getItem("leftTreeData")
const state = leftTreeData ? JSON.parse(leftTreeData) : [];

const mutations = {
    fillData(state, value) {
        state.push(...value)
        sessionStorage.setItem("leftTreeData", JSON.stringify(value))
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
