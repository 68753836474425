const state = {
    D_SHOW: false,
    BTN_SHOW: false,
    requests: [],
};

const mutations = {
    changeDSHOW(state, value) {
        state.D_SHOW = value;
    },
    changeBTNSHOW(state, value) {
        state.BTN_SHOW = value;
    },
    pushRequests(state, source) {
        state.requests = [...state.requests, source];
    },
    emptyRequests(state) {
        if (Setting.cancelPendingReq) {
            state.requests.forEach((xhr) => xhr.cancel());
            state.requests = [];
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
