import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import china from "../public/map/china.json";
import * as echarts from "echarts";
import axios from "axios";
import VueWorker from "vue-worker";

// 全局引入element ui框架和样式
import ElementUI from "element-ui";
// import { scrollBoard } from "@jiaminghi/data-view";
import scrollBoard from "@jiaminghi/data-view/lib/components/scrollBoard/index";

import "element-ui/lib/theme-chalk/index.css";
import VueVirtualScroller from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

// index.html引入了echarts，window就有这个属性，因此挂载在vue原型对象上
echarts.registerMap("china", china);
Vue.prototype.$echarts = echarts;

// 导入全局通用样式
import "./assets/css/global.css";

Vue.use(ElementUI);
Vue.use(scrollBoard);
Vue.use(VueVirtualScroller);
Vue.use(VueWorker);

// 将axios挂载在vue原型链上
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 图表自適應全局指令
Vue.directive("resize", {
  // 使用局部注册指令的方式
  // 指令的名称
  bind(el, binding) {
    // el为绑定的元素，binding为绑定给指令的对象
    let width = "",
      height = "";

    function isReize() {
      const style = document.defaultView.getComputedStyle(el);
      if (width !== style.width || height !== style.height) {
        binding.value(); // 关键
      }
      width = style.width;
      height = style.height;
    }
    el.__vueSetInterval__ = setInterval(isReize, 300);
  },
  unbind(el) {
    clearInterval(el.__vueSetInterval__);
  },
});
