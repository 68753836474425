<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'app',
}
</script>

<style lang="less">
@import '../src/assets/font/font.css';
#app {
  height: 100vh;
  font-family: SYHTotf;
}
</style>
