import Vue from 'vue'
import Vuex from 'vuex'
import system from './modules/systemmanage'
import archives from './modules/archivesmanage'
import leftTree from './modules/leftTree'
import leftTreeData from "./modules/leftTreeData";

// import dayEnergy from './modules/dayEnergy'



Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    system,
    archives,
    leftTree,
    leftTreeData
    // dayEnergy,
  }
})
