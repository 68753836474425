import Vue from "vue";
import VueRouter from "vue-router";
import store from "#/src/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: {
      title: "登录",
    },
  },
  {
    path: "/",
    // name: 'Main',
    component: () => import("@/views/main.vue"),
    children: [
      // 平台首页
      {
        path: "",
        redirect: "/home/overview",
      },
      {
        path: "home",
        name: "HomeView",
        component: () => import("@/views/HomeView/screen.vue"),
      },
      {
        path: "home/overview",
        redirect: "home/overview/overviewA",
        // 设置这个三级直接打开三级路由页面，但是二级路由里要加一个router-view
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            // name: 'SiteOverview',
            path: "overviewA",
            component: () => import("@/views/HomeView/SiteOverview.vue"),
            // 侧边菜单获取path设置高亮
            meta: {
              activeMenu: "/home/overview",
            },
          },
          {
            path: "home/overview/detail",
            name: "OverviewDetail",
            component: () => import("@/views/HomeView/OverivewDetail.vue"),
            meta: {
              activeMenu: "/home/overview",
            },
          },
          {
            path: "home/overview/stationDetail",
            name: "stationDetail",
            component: () => import("@/views/HomeView/Overview/index.vue"),
            meta: {
              activeMenu: "/home/overview",
            },
          },
        ],
      },
      // 电站详情页
      {
        path: "/home/screenDetails",
        name: "ScreenDetails",
        component: () => import("@/views/HomeView/StationDetail.vue"),
      },
      // 告警和离线逆变器列表
      {
        path: "/site/inverterSiteList",
        name: "SiteInverterList",
        component: () => import("@/views/HomeView/SiteInverterList.vue"),
      },
      // 实时监测
      {
        path: "ActualTimeMonitor/DeviceStatus",
        name: "DeviceStatus",
        component: () => import("@/views/ActualTimeMonitor/DeviceStatus/index.vue"),
      },
      {
        path: "ActualTimeMonitor/DeviceMonitor",
        name: "DeviceMonitor",
        component: () => import("@/views/ActualTimeMonitor/DeviceMonitor/index.vue"),
      },
      // 视频监控
      {
        path: "VideoMonitor",
        name: "VideoMonitor",
        component: () => import("@/views/VideoMonitor/index.vue"),
      },
      // 通讯监控
      {
        path: "CommunicateMonitor/Station",
        name: "StationMonitor",
        component: () => import("@/views/CommunicateMonitor/Station/index.vue"),
      },
      {
        path: "CommunicateMonitor/Gateway",
        name: "GatewayMonitor",
        component: () => import("@/views/CommunicateMonitor/Gateway/index.vue"),
      },
      {
        path: "CommunicateMonitor/Device",
        name: "DeviceMonitor2",
        component: () => import("@/views/CommunicateMonitor/Device/index.vue"),
      },
      // 报警分析
      {
        path: "AlarmAnalysis/EarlyWarn",
        name: "EarlyWarn",
        component: () => import("@/views/AlarmAnalysis/EarlyWarn/index.vue"),
      },
      {
        path: "AlarmAnalysis/ActualTimeWarn",
        name: "ActualTimeWarn",
        component: () => import("@/views/AlarmAnalysis/ActualTimeWarn/index.vue"),
      },
      {
        path: "AlarmAnalysis/HistoryWarn",
        name: "HistoryWarn",
        component: () => import("@/views/AlarmAnalysis/HistoryWarn/index.vue"),
      },
      // 运维器数据
      {
        path: "AlarmAnalysis/YunWeiWarn",
        name: "YunWei",
        component: () => import("@/views/AlarmAnalysis/YunWeiWarn/index.vue"),
      },
      // 运维器所有数据
      {
        path: "AlarmAnalysis/AllInformationWarn",
        name: "YunWei2",
        component: () => import("@/views/AlarmAnalysis/AllInformationWarn/index.vue"),
      },
      {
        path: "AlarmAnalysis/Statistics",
        name: "Statistics",
        component: () => import("@/views/AlarmAnalysis/Statistics/index.vue"),
      },
      // 光伏分析
      {
        path: "PVAnalysis/YieldElectricityStatistics",
        name: "YieldElectricityStatistics",
        component: () => import("@/views/PVAnalysis/YieldElectricityStatistics/index.vue"),
      },
      {
        path: "PVAnalysis/Incom",
        name: "IncomAnalysis",
        component: () => import("@/views/PVAnalysis/Incom/index.vue"),
      },
      {
        path: "PVAnalysis/YieldElectricityEfficiency",
        name: "YieldElectricityEfficiency",
        component: () => import("@/views/PVAnalysis/YieldElectricityEfficiency/index.vue"),
      },
      {
        path: "PVAnalysis/PVComparison/config",
        name: "PVComparison",
        component: () => import("@/views/PVAnalysis/PVComparison/Config/index.vue"),
      },
      {
        path: "PVAnalysis/PVComparison/Analysis",
        name: "PVComparison2",
        component: () => import("@/views/PVAnalysis/PVComparison/Analysis/index.vue"),
      },
      // 统计报表
      {
        path: "StatisticsJournaling/YieldElectricity",
        name: "YieldElectricityStatistics2",
        component: () => import("@/views/StatisticsJournaling/YieldElectricity/index.vue"),
      },
      {
        path: "StatisticsJournaling/Area",
        name: "Area",
        component: () => import("@/views/StatisticsJournaling/Area/index.vue"),
      },
      {
        path: "StatisticsJournaling/Incom",
        name: "IncomJournaling",
        component: () => import("@/views/StatisticsJournaling/Incom/index.vue"),
      },
      {
        path: "StatisticsJournaling/Efficiency",
        name: "Efficiency",
        component: () => import("@/views/StatisticsJournaling/Efficiency/index.vue"),
      },
      // 档案管理
      {
        path: "ArchivesManage/Station",
        name: "StationArchives",
        component: () => import("@/views/ArchivesManage/Station/index.vue"),
      },
      {
        path: "ArchivesManage/Gateway",
        name: "GatewayArchives",
        component: () => import("@/views/ArchivesManage/Gateway/index.vue"),
      },
      {
        path: "ArchivesManage/Device",
        name: "DeviceArchives",
        component: () => import("@/views/ArchivesManage/Device/index.vue"),
      },
      {
        path: "ArchivesManage/Video",
        name: "VideoArchives",
        component: () => import("@/views/ArchivesManage/Video/index.vue"),
      },
      // 数据字典
      {
        path: "DataDictionary/SamplingIndicators",
        name: "SamplingIndicators",
        component: () => import("@/views/DataDictionary/SamplingIndicators/index.vue"),
      },
      {
        path: "DataDictionary/IndicatorGrouping",
        name: "IndicatorGrouping",
        component: () => import("@/views/DataDictionary/IndicatorGrouping/index.vue"),
      },
      {
        path: "DataDictionary/DeviceType",
        name: "DeviceType",
        component: () => import("@/views/DataDictionary/DeviceType/index.vue"),
      },
      // 系统管理
      {
        path: "SystemManage/User",
        name: "UserManage",
        component: () => import("@/views/SystemManage/User/index.vue"),
      },
      {
        path: "SystemManage/Role",
        name: "RoleManage",
        component: () => import("@/views/SystemManage/Role/index.vue"),
      },
      {
        path: "SystemManage/Menu",
        name: "MenuManage",
        component: () => import("@/views/SystemManage/Menu/index.vue"),
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/Test.vue"),
  },
  {
    path: "/Carousel",
    name: "SiteCarousel",
    component: () => import("@/views/HomeView/SiteCarousel.vue"),
  },
  {
    path: "/home/home",
    name: "HomeView",
    component: () => import("@/views/HomeView/screen.vue"),
  },
  {
    path: "/home/overview/stationDetail/realData",
    name: "realData",
    component: () => import("@/views/HomeView/Overview/realData.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404/index.vue"),
  },
];

const router = new VueRouter({
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});

// 解决Loading chunk (\d)+ failed问题
router.onError((error) => {
  console.error(error);
  const pattern = /Loading chunk/g;
  // const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed && error.type === "missing") {
    // const targetPath = router.history.pending.fullPath
    router.push(targetPath);
  }
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 这里设置路由拦截
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "博通";
  }
  document.title = "博通";
  let token = window.sessionStorage.getItem("token"); //这里是登录页面缓存的token
  if (to.path === "/login") return next();
  if (!token) return next("/login");
  store.state.system.requests.forEach((xhr) => xhr.cancel()); // 通过遍历终止所有未完成的请求
  store.state.system.requests = []; // 执行完清空，等待下一次的页面的请求装载
  next();
});

export default router;
