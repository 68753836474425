const state = {
    AD_SHOW: false,
    BTN_SHOW: false,
    page: 1,
    pageSize: 10
}

const mutations = {
    changeDSHOW(state, value) {
        state.AD_SHOW = value
    },
    changeBTNSHOW(state, value) {
        state.BTN_SHOW = value
    },
    changePAGE(state, value) {
        state.page = value
    },
    changePAGESIZE(state, value) {
        state.pageSize = value
    }
}


export default {
    namespaced: true,
    state,
    mutations,
}